module services {
    export module integration {
        export class ediCusresStatusService implements interfaces.integration.IEDICusresStatusService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(isInbound: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "EDICusresStatus/GetDropdownList", {
                    isInbound: isInbound
                });
            }

            getForDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "EDICusresStatus/GetForDropdown", {
                });
            }        
            
            getForDropDownByCountry(countryId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "EDICusresStatus/GetForDropDownByCountry", {
                    countryId: countryId
                });
            }     
        }
    }
    angular.module("app").service("ediCusresStatusService", services.integration.ediCusresStatusService);
}